import React from 'react'
import {Button, Checkbox, Form, Modal} from 'semantic-ui-react'
import {withRouter} from 'react-router-dom';


class AgreeTermsAndConditions extends React.Component {


    state = {
        agree: false,
        modalOpen: false
    };

    handleOpen = () => this.setState({modalOpen: true});
    handleClose = () => this.setState({modalOpen: false});

    modalResponse = () => (
        <Modal
            trigger={<Button>Show Modal</Button>}
            header='Reminder!'
            content='Call Benjamin regarding the reports.'
            actions={['Snooze', { key: 'done', content: 'Done', positive: true }]}
        />
    );


    toggleCheckbox = e => {
        this.setState({agree: !this.state.agree})
    };

    handleFormSubmit = e => {
        e.preventDefault();
        if (this.state.agree) {
            console.log('agree');
            this.props.history.push('/servicios/b1b2-visa-de-turista/aplicar');
        } else {
          this.modalResponse()
        }
    };


    render() {
        return (
            <Form onSubmit={this.handleFormSubmit}>
                <Form.Field>
                    <Checkbox name='agree' checked={this.state.agree} onChange={this.toggleCheckbox}
                              label='Para proceder con el servicio por favor acepte lo anterior.'/>
                </Form.Field>
                <Button type='submit'>Iniciar tramite</Button>
            </Form>
        )
    }
}

export default withRouter(AgreeTermsAndConditions);
