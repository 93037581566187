import React from 'react';
import Logo from "./Logo";
import NavBarMenu from "./NavBarMenu";
import {Container} from "semantic-ui-react";

const AppHeader = props => {
    return (
        <Container>
            <Logo/>
            <NavBarMenu/>
        </Container>
    )
};


export default AppHeader;