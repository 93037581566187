import i18n from "i18next";
import { initReactI18next} from "react-i18next";
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
.use(Backend)
.use(LanguageDetector)
.use(initReactI18next)
.init({
    ln: 'es',
    backend: {
      loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
    },
    fallbackLng: 'en',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,

    interpolation:{
        escapeValue: false,
        formatSeparator: ','
    },
    react: {
      wait: true
    }
});

export default i18n;