import React, {Component} from "react";
import {Responsive, Segment, Container, Header} from "semantic-ui-react";
import HeaderContentContainer from "./HeaderContentContainer";
import '../about.css';
import logoNudee from "../assets/nuu_dee_Produce_Ing_Logo_MgmtCslt.jpg"
import aboutInfo from '../data/about.json';


const containerAboutStyle = {
    border: '1px solid rgba(34, 36, 38, 0.15)',
    borderRadius: '5px 5px 5px 5px',
    padding: '1em',
};

class HeaderAbout extends Component {
    state = {history: null, mission: null};

    componentDidMount() {
        this.loadAboutInf();
    }

    loadAboutInf = () => {
        const {history, mission} = aboutInfo;
        this.setState({history, mission});
    };

    render() {
        return (
            <div>
                <Responsive>
                    <Segment
                        textAlign='center'
                        vertical
                    >
                        <HeaderContentContainer srcImage={logoNudee}
                                                title='Nosotros' imgHeaderClass='tinyImgHeader'/>
                    </Segment>
                </Responsive>

                <Responsive>
                    <Segment
                        textAlign='left'
                        style={{minHeight: 350, padding: '1em 0em'}}
                        vertical
                    >
                        <Container style={containerAboutStyle} textAlign='center'>
                            <Header
                                as='h2'
                                content='Historia'
                                style={{
                                    fontSize: '1.7em',
                                    fontWeight: 'normal',
                                    marginTop: '0.5em',
                                }}
                            />
                            <p style={{fontSize: '1.2em'}}>
                                {this.state.history}
                            </p>
                            <Header
                                as='h2'
                                content='Misión'
                                style={{
                                    fontSize: '1.7em',
                                    fontWeight: 'normal',
                                    marginTop: '0.5em',
                                }}
                            />
                            <p style={{fontSize: '1.2em'}}>
                                {this.state.mission}
                            </p>
                        </Container>
                    </Segment>
                </Responsive>
            </div>
        )
    }
}

export default HeaderAbout;