import React from 'react';
// import PropTypes from 'prop-types';
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";

const AppFrame = ({Body}) => {
    return (
        <div>
            <header style={{margin:20}}>
                <AppHeader/>
            </header>
            <main>
                <Body/>
            </main>
            <footer>
                <AppFooter>
                </AppFooter>
            </footer>
        </div>
    )
};

// AppFrame.propTypes = {
//     body: PropTypes.element.isRequired
// };

export default AppFrame;