import React from 'react';
import {Grid, Header, Responsive, Image} from "semantic-ui-react";
import ContactItemsContainer from "./ContactItemsContainer";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import logoNudee from "../assets/nuu_dee_Produce_Ing_Logo_MgmtCslt.jpg"

const Logo = () => {
    const {t} = useTranslation();

    return (
        <Grid columns={2} stackable>
            <Grid.Row>
                <Grid.Column width={8}>
                    <Header as='h2'>
                        <Link to='/'>
                            <Image src={logoNudee} size='tiny' verticalAlign='middle'/>
                            <Responsive minWidth={768}>
                                <span> {t('title')}</span>
                            </Responsive>
                        </Link>
                    </Header>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                        <ContactItemsContainer position={true}/>
                    </Responsive>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};


export default Logo;