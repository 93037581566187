import React, {Component} from 'react';
import {Container, Header, Grid, Responsive, Segment} from "semantic-ui-react";
import HeaderContentContainer from "./HeaderContentContainer";
import '../services.css';
import {Link} from "react-router-dom";
import servicesInfo from "../data/servicesInfo";
import touristImg from "../assets/tourist-ra3oAd6hrnM.png"

const ItemService = ({services}) => {
    return (
        services.map(serviceItem => {
            const {id, name, description, url} = serviceItem;
            return (
                <Grid.Column key={`${url}-${id}`}>
                    <Header as='h4' attached='top' block>
                        {name}
                    </Header>
                    <Segment attached>
                        {description}
                        <div style={{marginTop:10}}>
                            <Link to={`contacto`}>
                                <strong>Solicitar informes >></strong>
                            </Link>
                        </div>
                    </Segment>
                </Grid.Column>
            )
        })
    )
};

class Services extends Component {

    state = {services: []};

    componentDidMount() {
        this.loadServices();
    }

    loadServices = () => {
        const services = servicesInfo;
        this.setState({services});
    };

    render() {
        const services = this.state.services;
        return (
            <div>
                <Responsive>
                    <Segment
                        textAlign='center'
                        style={{padding: '1em 0em'}}
                        vertical
                    >
                        <HeaderContentContainer title='Servicios' imgHeaderClass='wideImgHeader'
                                                headerTitleContent='headerTitleContent' srcImage={touristImg}/>
                    </Segment>
                </Responsive>
                <Responsive>
                    <Segment style={{minHeight: 50, padding: '1em 0em'}} textAlign='left' vertical>
                        <Container>
                            <Grid columns={3} stackable>
                                <ItemService services={services}/>
                            </Grid>
                        </Container>
                    </Segment>
                </Responsive>
            </div>
        );
    }
}

export default Services;