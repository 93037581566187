import React from 'react';
import {Container, Header} from "semantic-ui-react";

const TermsAndConditions = () => {
    return (
        <Container fluid  textAlign='justified'>
            <Header as='h2'>Iniciar el tramite</Header>
            <p>
                Lea y Acepte los Terminos y Condiciones Vigentes si esta de acuerdo con iniciar
                el Tramite
            </p>
            <Header as='h3'>DECLARACIONES, CLÁUSULAS, TÉRMINOS Y CONDICIONES.</Header>
            <div>
                <Header as='h4'>
                    DECLARACIONES:
                </Header>
                <p>
                    I. El nombre comercial de Consultoría en Administración Ñuu dee funge actualmente como Persona
                    Física: Claudia Marcela Rojas Reyes, con R.F.C.: RORC700627-QMA.
                    II. Nuestra Misión es “Gestionar ante distintas instituciones federales, estatales y municipales el
                    recurso monetario para coadyuvar al desarrollo económico y educativo de la región; ser mentores de
                    emprendedores (as); tramitar Visas de no Inmigrante para América del Norte; comercialización de
                    productos de la región; e impartir Cursos de Inglés y de Administración de Negocios”.
                    III. Domicilio legal es Venustiano Carranza #9-D. Centro, en la Heroica ciudad de Huajuapan de León,
                    Oaxaca. México.
                </p>
                <Header as='h4'>
                    CLÁUSULAS:
                </Header>
                <p>

                    I. MONTO POR HONORARIOS. El pago de honorarios está sujeto a su edad y/o grado de estudios. Por lo
                    que se le informará del monto por escrito.
                    II. FORMA DE PAGO. Será en Banco Santander, sucursal 0106, cuenta 56 61 47 69 26 1, CLABE
                    interbancaria 01 46 14 56 61 47 69 26 11.
                    III. DISPOSICIONES FISCALES. Solicitamos rellene sus datos fiscales para extenderle factura.
                    IV. CONFIDENCIALIDAD. El personal de Consultoría en Administración Ñuu dee y/o Claudia Marcela Rojas
                    Reyes no podrá divulgar por ningún medio la información proporcionada en el formato de solicitud de
                    visa.
                    V. TERMINACIÓN ANTICIPADA. Consultoría en Administración Ñuu dee / Claudia Marcela Rojas Reyes se
                    reserva el derecho de terminar por anticipado algún trámite si existe dolo, engaño, malas
                    intenciones o si el solicitante miente en el formato.
                    VI. VIGENCIA. La vigencia es limitada, inicia con el llenado de datos y a partir de ahí con 45 días
                    calendario.
                    VII. CONTROVERSIAS. Las partes convienen expresamente que el presente contrato es de naturaleza
                    civil, por lo que se someten al Código Civil para el Estado de Oaxaca, México; así como a la
                    jurisdicción de los tribunales competentes del Estado, por lo tanto renuncian al fuero que pudiera
                    corresponderles en razón de sus domicilios presentes o futuros.
                </p>
                <Header as='h4'>
                    TÉRMINOS Y CONDICIONES:
                </Header>
                <p>
                    I. El solicitante proporcionará información verídica para el llenado del Formato D-160.
                    II. Consultoría en Administración Ñuu dee / Claudia Marcela Rojas Reyes se deslinda de falsedades,
                    errores u omisiones que con dolo haga el solicitante.
                    III. El solicitante pagará el arancel vigente en Banco BANAMEX o SCOTIA BANK. No se aceptan otros
                    bancos.
                    IV. El arancel de visa es NO REEMBOLSABLE.
                    V. En caso de no salir beneficiado con la aprobación de su visa se le devolverá el 50% de lo
                    estipulado en el contrato. Para lo cual, tiene que enviar un correo solicitando su reembolso y
                    motivos que le dio el cónsul para ser rechazado.
                    VI. La elegibilidad la otorga el cónsul. Consultoría en Administración Ñuu dee / Claudia Marcela
                    Rojas Reyes no prueban las visas, nosotros somos intermediarios para facilitarle el proceso.
                    Nosotros no tenemos la facultad para aprobar visas.
                    VII. En caso de ser visas familiares o grupales tienen que tener en mente que todos deben de aplicar
                    para el mismo tipo de visa, en caso de solicitar visas distintas los trámites se realizarán por
                    separado.
                    VIII. En caso de visas de trabajo deben de proporcionar el contrato del patrocinador.
                </p>
                <p>
                    Para proceder con el servicio por favor acepte lo anterior.
                </p>
            </div>
        </Container>
    )
};

export default TermsAndConditions;