import React from 'react';
import {Container, Grid, List, Segment} from "semantic-ui-react";
import '../footer.css';

const AppFooter = () => {
    return (
        <Container style={{marginBottom: '1em'}}>
            <Segment inverted>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column>
                            <h3>Consultoría en Administración Ñuu dee</h3>
                            <List>
                                <h4>Tramites de</h4>
                                <List.Item>
                                    Visa y pasaporte a E. U. A
                                </List.Item>
                                <List.Item>Actas de nacimiento de México y E.U.A</List.Item>
                                <List.Item>Proyectos productivos</List.Item>
                            </List>
                        </Grid.Column>
                        <Grid.Column>
                            <h3>Servicios</h3>
                            <List>
                                <h4>Visas</h4>
                                <List.Item>
                                    B1/B2 de turista
                                </List.Item>
                                <List.Item>
                                    H2-A Agricultura
                                </List.Item>
                                <List.Item>
                                    H2-B No agricultura
                                </List.Item>
                            </List>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </Container>
    )
};

export default AppFooter;