import React from 'react';
import Services from "./Services";
import AppFrame from "./AppFrame";

const ServiceContainer =() =>{
   return (
       <AppFrame Body={Services} mobile/>
   )
};

export default  ServiceContainer;