import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import CONSULTINGSERVICES from '../data/CoverPageSite';
import {Link} from 'react-router-dom';
import {Container} from "semantic-ui-react";

const CoverPageContainer = () => (
    <Container style={{margin: 20}}>
        <Carousel infiniteLoop autoPlay showThumbs={false}>{
            CONSULTINGSERVICES.map(CONSULTING => (
                <div key={CONSULTING.id}>
                    <img src={CONSULTING.image} alt={CONSULTING.title}/>
                    <p className="legend">
                        <Link to={CONSULTING.link}>{CONSULTING.title}</Link>
                    </p>
                </div>
            ))
        }
        </Carousel>
    </Container>
);

export default CoverPageContainer;