import React, {Component} from 'react';
import HeaderContentContainer from "./HeaderContentContainer";
import {Responsive, Segment, Container, Grid} from "semantic-ui-react";
import AppFrame from "./AppFrame";
import FormContact from './FormContact';
import ContactItem from "./ContactItem";
import contactImg from "../assets/notebook-pen.jpg"

// import {MapContainer} from "./MapContainer";

const Body = ({mobile}) => {
    return (
        <div style={{marginBottom: "1rem"}}>
            <Responsive minWidth={Responsive.onlyMobile.minWidth}>
                <Container>
                    <Segment
                        textAlign='center'
                        style={{minHeight: '3rem', padding: '1em 0em'}}
                        vertical
                    >
                        <HeaderContentContainer title='Contacto' imgHeaderClass='wideImgHeader'
                                                headerTitleContent='headerTitleContent'
                                                srcImage={contactImg}/>

                    </Segment>

                    <Grid columns={3} stackable>
                            <Grid.Column width={16}>
                                <Segment style={{textAlign: "center"}}>
                                    <h3>Envianos un mensaje</h3>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <Segment style={{fontSize: '1rem', wordBreak: 'break-word'}}>
                                    <ContactItem/>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column width={10}>
                                <Segment>
                                    <FormContact/>
                                </Segment>
                            </Grid.Column>
                    </Grid>
                </Container>
            </Responsive>
        </div>
    );
};


class ContactPageContainer extends Component {
    render() {
        return (
            <AppFrame Body={Body} mobile/>
        )
    }
}

export default ContactPageContainer;