import React from 'react';
import {Header} from "semantic-ui-react";

const ContactItem = () => {
    return (
        <div>
            <Header as='h4'>
                Venustiano Carranza #9-D. Centro. Huajuapan de león, Oaxaca. México. 69000
            </Header>
            <Header as='h4'>
                Tel.: (953) 532-0579
            </Header>
            <Header as='h4'>
                e-mail: <a href="mailto:consultoria.nuudee@gmail.comcom">consultoria.nuudee@gmail.com</a>
            </Header>
        </div>
    )
};

export default ContactItem;