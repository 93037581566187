import React, {Component} from 'react';
import AppFrame from "./AppFrame";
import HeaderAbout from "./HeaderAbout";

class AboutContainer extends Component {
    render() {
        return (
            <AppFrame Body={HeaderAbout} mobile/>
        )
    }
}

export default AboutContainer;