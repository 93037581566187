import tourist from '../assets/tourist-ra3oAd6hrnM.png';
import farm from '../assets/farm-dcnfqXisV4.png';
import construction from '../assets/construction-g0oeX29ec.png';

const consultingServices = [
    {
        id :1,
        title: 'B1/B2 Visa de turista',
        link: '/services/b1b2-visa-de-turista',
        image: tourist
    },
    {
        id:2,
        title: 'H2-A Agricultura',
        link: '/services/h2-a-agricultura',
        image: farm,
    },
    {
        id:3,
        title: 'H2-B No Agricultura',
        link: '/services/h2-b-no-agricultura',
        image: construction,
    }
];

export default consultingServices;

