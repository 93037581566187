import React, {useState} from 'react';
import {Menu} from "semantic-ui-react";
import {Link} from 'react-router-dom';
// import LanguageSelector from "./LanguajeSelector";
import {useTranslation} from "react-i18next";


const NavBarMenu = () => {
    const [activeItem, setActiveItem] = useState('');

    const handleItemClick = (e, {name}) => {
        setActiveItem(name);
    };

    const {t} = useTranslation();

    return (
        <Menu stackable>
            <Menu.Item name='home' to='/'
                       as={Link}
                       active={activeItem === 'home'}
                       onClick={handleItemClick}
            >
                {t('menu.home')}
            </Menu.Item>
            <Menu.Item name='about' to= '/acerca'
                       as={Link}
                       active={activeItem === 'about'}
                       onClick={handleItemClick}
            >
                {t('menu.about')}
            </Menu.Item>
            <Menu.Item name='services' to= '/servicios'
                       as={Link}
                       active={activeItem === 'services'}
                       onClick={handleItemClick}
            >
                {t('menu.services')}
            </Menu.Item>
            <Menu.Item name='contact' to= '/contacto'
                       as={Link}
                       active={activeItem === 'contact'}
                       onClick={handleItemClick}
            >
                {t('menu.contact')}
            </Menu.Item>
            {/*<Menu.Item name='language'>*/}
            {/*    <LanguageSelector/>*/}
            {/*</Menu.Item>*/}
        </Menu>
    )
};

export default NavBarMenu;