import React, {Component} from 'react';
import HeaderContentContainer from "./HeaderContentContainer";
import {Responsive, Segment, Container, Grid} from "semantic-ui-react";
import AppFrame from "./AppFrame";
import turistImg from "../assets/tourist-ra3oAd6hrnM.png";
import TermsAndConditions from "../assets/declarations-clauses-term-and-conditions";
import AgreeTermsAndConditions from "./AgreeTermsAndConditions";


const Body = ({mobile}) => {
    return (
        <div style={{marginBottom: "1rem"}}>
            <Responsive minWidth={Responsive.onlyMobile.minWidth}>
                <Segment
                    textAlign='center'
                    style={{minHeight: 350, padding: '1em 0em'}}
                    vertical
                >
                    <HeaderContentContainer title='B1/B2 Visa de turista' imgHeaderClass='wideImgHeader'
                                            headerTitleContent='headerTitleContent'
                                            srcImage={turistImg}/>
                </Segment>
            </Responsive>
            <Responsive>
                <Container>
                    <Grid>
                        <Grid.Row columns={3} stretched>
                            <Grid.Column width={16}>
                                <Segment style={{textAlign: "center", marginBottom: "1rem"}}>
                                    <TermsAndConditions/>
                                    <AgreeTermsAndConditions/>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </Responsive>
        </div>
    );
};


class ServiceTouristConditionsContainer extends Component {
    render() {
        return (
            <AppFrame Body={Body} mobile/>
        )
    }
}

export default ServiceTouristConditionsContainer;