import React from "react";
import {Button, Form} from 'semantic-ui-react';
import api from "../api/nuudee";

const txtFieldState = {
    value: "",
    valid: true,
    typeMismatch: false,
    errMsg: "", //this is where our error message gets across
    textSuccess: ""
};

const ErrorValidationLabel = ({txtLbl}) => (
    <label htmlFor="" style={{color: "red"}}>
        {txtLbl}
    </label>
);

class FormContact extends React.Component {
    state = {
        fullname: {
            ...txtFieldState,
            fieldName: "Nombre completo",
            required: true,
            requiredTxt: "El nombre completo es requerido"
        },
        email: {
            ...txtFieldState,
            fieldName: "Email",
            required: true,
            requiredTxt: "El email es requerido",
            formatErrorTxt: "Escriba un email valido"
        },
        phone: {
            ...txtFieldState,
            fieldName: "Télefono",
            required: false,
            requiredTxt: "El telefono es requerido"
        },
        message: {
            ...txtFieldState,
            fieldName: "Mensaje",
            required: false,
            requiredTxt: "El mensaje es requerido"
        },
        faxonly: {
            ...txtFieldState,
            fieldName: "faxonly",
            required: false,
            requiredTxt: ""
        },
        allFieldsValid: false
    };

    reduceFormValues = formElements => {
        const arrElements = Array.prototype.slice.call(formElements); //we convert elements/inputs into an array found inside form element

        //we need to extract specific properties in Constraint Validation API using this code snippet
        const formValues = arrElements
            .filter(elem => elem.name.length > 0)
            .map(x => {
                const {typeMismatch} = x.validity;
                const {name, type, value} = x;

                return {
                    name,
                    type,
                    typeMismatch, //we use typeMismatch when format is incorrect(e.g. incorrect email)
                    value,
                    valid: x.checkValidity()
                };
            })
            .reduce((acc, currVal) => {
                //then we finally use reduce, ready to put it in our state
                const {value, valid, typeMismatch} = currVal;
                const {fieldName, requiredTxt, formatErrorTxt} = this.state[
                    currVal.name
                    ]; //get the rest of properties inside the state object

                //we'll need to map these properties back to state so we use reducer...
                acc[currVal.name] = {
                    value,
                    valid,
                    typeMismatch,
                    fieldName,
                    requiredTxt,
                    formatErrorTxt
                };

                return acc;
            }, {});

        return formValues;
    };

    checkAllFieldsValid = formValues => {
        return !Object.keys(formValues)
            .map(x => formValues[x])
            .some(field => !field.valid);
    };

    onSubmit = e => {
        e.preventDefault();
        const form = e.target;

        //we need to extract specific properties in Constraint Validation API using this code snippet
        const formValues = this.reduceFormValues(form.elements);
        const allFieldsValid = this.checkAllFieldsValid(formValues);
        //note: put ajax calls here to persist the form inputs in the database.

        if (allFieldsValid) {
            const {fullname, email, phone, message, faxonly} = formValues;
            const params = new URLSearchParams();
            params.append('fullname', fullname.value);
            params.append('email', email.value);
            params.append('phone', phone.value);
            params.append('message', message.value);
            params.append('faxonly', faxonly.value);

            api({
                method: 'post',
                url: '/contact',
                data: params
            }).then(result => {
                let textSuccess = result.data;
                this.setState({textSuccess: textSuccess.data});
            }).catch(error => {
                this.setState({allFieldsValid: false});
            });
        }

        //END

        this.setState({...formValues, allFieldsValid}); //we set the state based on the extracted values from Constraint Validation API

    };

    render() {
        const {email, fullname, phone, message, allFieldsValid, textSuccess} = this.state;
        const successFormDisplay = allFieldsValid ? "block" : "none";
        const inputFormDisplay = !allFieldsValid ? "block" : "none";
        const renderEmailValidationError = email.valid ? (
            ""
        ) : (
            <ErrorValidationLabel
                txtLbl={email.typeMismatch ? email.formatErrorTxt : email.requiredTxt}
            />
        );

        const renderDateValidationError = phone.valid ? (
            ""
        ) : (
            <ErrorValidationLabel txtLbl={phone.requiredTxt}/>
        );

        const renderFnameValidationError = fullname.valid ? (
            ""
        ) : (
            <ErrorValidationLabel txtLbl={fullname.requiredTxt}/>
        );

        const renderMessageValidationError = message.valid ? (
            ""
        ) : (
            <ErrorValidationLabel txtLbl={message.requiredTxt}/>
        );

        return (
            <>
                <div style={{display: successFormDisplay}}>
                    <h3 style={{textAlign: "center"}}>Gracias por ponerse en contacto</h3>
                    <p style={{textAlign: "center"}}>
                        {textSuccess}
                    </p>
                </div>
                <div className="form-input" style={{display: inputFormDisplay}}>
                    <Form
                        onSubmit={this.onSubmit}
                        noValidate
                    >

                        <Form.Input placeholder='Nombre completo' name="fullname" required/>
                        <p>
                            {renderFnameValidationError}
                        </p>

                        <Form.Input type="email" name="email" placeholder="Email" required/>
                        <p>
                            {renderEmailValidationError}
                        </p>

                        <Form.Input type="text" name="phone" placeholder="Tel:" required/>
                        <p>
                            {renderDateValidationError}
                        </p>

                        <Form.TextArea name='message' rows='3' placeholder='Mensaje' required/>
                        <p>
                            {renderMessageValidationError}
                        </p>

                        <div className="form-group" style={{display: 'none'}}>
                            <Form.Input type="checkbox" name="faxonly" id="faxonly"/>
                        </div>
                        <Button type="submit">Enviar</Button>
                    </Form>
                </div>
            </>
        );
    }
}

export default FormContact;