import React, {Component} from 'react';
import AppFrame from "./AppFrame";
// import CoverPageContainer from "./CoverPageContainer";
import {Container, Grid, Segment, Header, Responsive} from "semantic-ui-react";
import CoverPageContainer from "./CoverPageContainer";

const marginBody = {
    marginTop: '1em',
    marginBottom: '1.05em'
};

const Body = ({mobile}) => {
    return (
        <Responsive>

        <CoverPageContainer/>
            <Container style={marginBody} textAlign='center'>
                <Segment>
                    <Grid>
                        <Grid.Row columns={1}>
                            <Grid.Column>
                                <Header
                                    as='h2'
                                    content='¿Porque deben elegir consultoría Ñuu dee?'
                                    style={{
                                        fontSize: mobile ? '1.5em' : '1.7em',
                                        fontWeight: 'normal',
                                        marginTop: '0.5em',
                                    }}
                                />

                                <p style={{fontSize: '1.2em'}}>
                                    Debido a las estafas en este tipo de trámites, le recomendamos elegir lugares
                                    confiables, con experiencia y con estabilidad laboral. Nosotros estamos en el mismo
                                    domicilio desde el 2009 y registrados ante el Servicio de Administración Tributaria
                                    (SAT).

                                    Te daremos una asesoría confiable, te asesoramos desde el inicio hasta el fin; no
                                    tomamos cualquier caso, si vemos que tiene probabilidades de calificar se lo
                                    decimos, aunque siempre la elegibilidad de Visa la otorga el Cónsul.
                                </p>
                            </Grid.Column>
                            <Grid.Column>
                                {/*<h4>Trayectoria</h4>*/}
                                <Header
                                    as='h2'
                                    content='Trayectoria'
                                    style={{
                                        fontSize: mobile ? '1.5em' : '1.7em',
                                        fontWeight: 'normal',
                                        marginTop: '0.5em',
                                    }}
                                />
                                <p style={{fontSize: '1.2em'}}>
                                    Somos profesionistas con cédulas profesionales en la República Mexicana. La
                                    fundadora trabajó en el ámbito de Comercio Exterior durante 14 años, específicamente
                                    en Trámites de Exportación en Tijuana, Baja California e Indiana, Estados Unidos.
                                    Debido a su calidad de migrante y de que ha visto que hay gente que puede calificar
                                    para una visa es que ofrece los servicios para que las personas vayan a ver a sus
                                    familiares o a trabajar legalmente.
                                </p>
                            </Grid.Column>

                        </Grid.Row>
                    </Grid>
                </Segment>
            </Container>
        </Responsive>

    )
};

class HomeContainer extends Component {
    render() {
        return (
            <AppFrame Body={Body} mobile/>
        )
    }
}

export default HomeContainer;