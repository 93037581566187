import React from 'react';
// import {List, Icon} from "semantic-ui-react";
import {Grid, Icon, Header} from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const ContactItemsContainer = ({position}) => {
    console.log('position,', position);
    const { t } = useTranslation();

    return (
        <Grid className={'item-contact'}>
            <Grid.Column width={4}>
                <Header as={'h3'}>
                    <Icon name='phone' size='large'/>
                    {t('contact.phone')}
                </Header>
                <div className="content-contact">
                    <a href='#phone'>(953) 532-0579</a>
                </div>
            </Grid.Column>
            <Grid.Column width={6}>
                <Header as={'h3'}>
                    <Icon name='point' size='large'/>
                    {t('contact.address')}
                </Header>
                <a href='#address'>V. Carranza #9-D. Col: Centro. Huajuapan de León, Oax.</a>
            </Grid.Column>
            <Grid.Column width={6}>
                <Header as={'h3'}>
                    <Icon name='clock outline' size='large'/>
                    {t('contact.hourly')}
                </Header>
                <a href='#hourly'>Lunes -Viernes de 9:00 am a 7:00 pm.</a>
            </Grid.Column>
        </Grid>
    );
};

export default ContactItemsContainer;