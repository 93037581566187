import React from 'react';
import {Container, Header, Image} from "semantic-ui-react";

const containerHeaderStyle = {
    border: '1px solid rgba(34, 36, 38, 0.15)',
    borderRadius: '5px 5px 5px 5px',
};

const HeaderContentContainer = ({title, srcImage, imgHeaderClass, headerTitleContent}) => {
    return (
        <div>
            <Container style={containerHeaderStyle}>
                <Image src={srcImage} className={imgHeaderClass}/>

                <Header
                    as='h2'
                    content={title}
                    className={headerTitleContent}
                />
            </Container>
        </div>
    )

};

export default HeaderContentContainer;