import React, {Component, Suspense} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import './App.css';
import HomeContainer from "./components/HomeContainer";
import AboutContainer from "./components/AboutContainer";
import ServiceContainer from "./components/ServiceContainer";
import ContactPageContainer from "./components/ContactPageContainer";
import ServiceTouristConditionsContainer from "./components/ServiceTouristConditionsContainer";
import ServiceAgriculturalConditionsContainer from "./components/ServiceAgriculturalConditionsContainer";
import ServiceNoAgriculturalConditionsContainer from "./components/ServiceNoAgriculturalConditionsContainer";

class App extends Component {
    render() {
        return (
            <Router>
                {/*<div>*/}
                    <Suspense fallback={null}>
                        <Route exact path="/" component={HomeContainer}/>
                        <Route exact path="/acerca" component={AboutContainer}/>
                        <Route exact path="/servicios" component={ServiceContainer}/>
                        <Route exact path="/servicios/b1b2-visa-de-turista" component={ServiceTouristConditionsContainer}/>
                        {/*<Route exact path="/servicios/b1b2-visa-de-turista/aplicar" component={Tourist}/>*/}
                        <Route exact path="/servicios/h2-a-agricultura" component={ServiceAgriculturalConditionsContainer}/>
                        {/*<Route exact path="/servicios/h2-a-agricultura/aplicar" component={ServiciosVisa}/>*/}
                        <Route exact path="/servicios/h2-b-no-agricultura" component={ServiceNoAgriculturalConditionsContainer}/>
                        {/*<Route exact path="/servicios/h2-b-no-agricultura/aplicar"/>*/}
                        <Route exact path="/contacto" component={ContactPageContainer}/>
                        {/*<Route exact path="/companies/" component={HomeContainer}/>*/}
                        {/*<Route exact path="/companies/about" component={AboutContainer}/>*/}
                        {/*<Route exact path="/companies/services" component={HomeContainer}/>*/}
                        {/*<Route exact path="/companies/contact" component={ContactPageContainer}/>*/}

                    </Suspense>
                {/*</div>*/}
            </Router>
        );
    }
}

export default App;
